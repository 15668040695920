import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesCreditCardsDropDown } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesCreditCardsDropDown> =>
    createStyles({
      rootOption: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      iconRoot: {
        display: 'inline',
        verticalAlign: 'middle',
      },
      deleteButton: {
        position: 'absolute',
        right: 0,
      },
      deleteIcon: {
        fontSize: theme.spacing(2), // 16px
      },
      menuItem: {
        padding: theme.spacing(1, 1.25), // 8px 10px
        color: theme.palette.text.secondary,

        '&$menuItemSelected': {
          backgroundColor: 'inherit',
        },
      },
      menuItemSelected: {
        color: theme.palette.primary.main,
      },
      dividerRoot: {
        margin: theme.spacing(1.25, 1.75), // 10px 14px
      },
      optionText: {
        fontSize: '14px',
        lineHeight: '18px',
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
    }),
);
