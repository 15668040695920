import React, { FC, useMemo } from 'react';
import { INumberOfPaymentsDropdown } from 'ui/common/NumberOfPaymentsDropdown/types';
import DropDown, { TDropDownOption } from 'ui/common/inputs/DropDown';
import { useTranslation } from 'react-i18next';
import Typography from 'ui/common/Typography';
import useStyles from './styles';

const NumberOfPaymentsDropdown: FC<INumberOfPaymentsDropdown> = ({
  addBorderRadius,
  maxNumberOfPayments,
  value,
  onChange,
  outlinedInputStyle,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const dropDownOptions = useMemo<TDropDownOption[]>(() => {
    return Array(maxNumberOfPayments)
      .fill(0)
      .map((_, index) => ({
        id: index + 1,
        value: index + 1,
        text: (
          <Typography className={classes.optionText} variant="body1">
            {index + 1}
          </Typography>
        ),
      }));
  }, [classes.optionText, maxNumberOfPayments]);

  return (
    <DropDown
      label={t('checkout.numberOfPayments')}
      required
      outlinedInputStyle={outlinedInputStyle}
      isShowLabel
      size="large"
      value={value}
      addBorderRadius={addBorderRadius}
      onChange={onChange}
      dropDownOptions={dropDownOptions}
    />
  );
};

export default NumberOfPaymentsDropdown;
