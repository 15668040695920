import React, { FC } from 'react';

import { Button } from '@material-ui/core';

import Typography from 'ui/common/Typography';
import Icon from 'ui/common/icons/Icon';

import useStyles from './styles';

import { IPaymentMethod } from './types';

const PaymentMethod: FC<IPaymentMethod> = ({
  isActive,
  onClick,
  text,
  icon = 'icon-credit-card',
}) => {
  const classes = useStyles({ isActive, onClick, text, icon });

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.labelRoot,
        outlined: classes.outlinedRoot,
        contained: classes.containedRoot,
      }}
      color="primary"
      variant={isActive ? 'contained' : 'outlined'}
      onClick={onClick}
      disableRipple
    >
      <Icon aria-label="payment-icon" name={icon} classes={{ root: classes.topIcon }} />
      <Typography
        color={isActive ? 'light' : 'textSecondary'}
        fontWeight={isActive ? 'medium' : 'regular'}
        variant="body1"
        fontSize={16}
        className={classes.label}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default PaymentMethod;
