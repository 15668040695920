import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { IPaymentMethod, TStylesPaymentMethod } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesPaymentMethod, IPaymentMethod> =>
    createStyles({
      root: {
        height: theme.spacing(10), // 80px
        maxWidth: theme.spacing(18.25), // 146px
        width: '100%',
        minWidth: '100%',
        borderRadius: theme.spacing(0.75), // 6px,

        [theme.breakpoints.up('md')]: {
          minWidth: theme.spacing(19.875), // 159px
        },
      },
      labelRoot: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'initial',
      },
      topIcon: {
        fontSize: theme.spacing(4), // 32px
        color: ({ isActive }) =>
          isActive ? theme.palette.background.paper : theme.palette.primary.main,
        marginBottom: theme.spacing(4 / 8),
      },
      outlinedRoot: {
        borderWidth: '1.5px',
        borderColor: theme.palette.primary.main,
      },
      containedRoot: {
        boxShadow: 'none !important',
        '&:disabled': {
          color: theme.palette.background.paper,
          backgroundColor: theme.palette.primary.main,
        },
      },
      label: {
        lineHeight: '16px',
      },
    }),
);
