import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesNumberOfPaymentsDropdown } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesNumberOfPaymentsDropdown> =>
    createStyles({
      optionText: {
        fontSize: '14px',
        lineHeight: '18px',
        display: 'initial',
        [theme.breakpoints.up('md')]: {
          fontSize: '16px',
          lineHeight: '20px',
        },
      },
    }),
);
