import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';

import { TStylesAgeValidationSection } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesAgeValidationSection> =>
    createStyles({
      ageValidationTitle: {
        fontSize: '22px',
        lineHeight: '24px',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
          fontSize: '30px',
          lineHeight: '34px',
        },
      },
      limitationItemsExistWrapper: {
        margin: theme.spacing(12 / 8, 0, 5 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(14 / 8, 0, 3 / 8),
        },
      },
      limitationItemsExist: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
        color: theme.palette.text.secondary,
        display: 'inline',
      },
      label: {
        color: theme.palette.grey[600],
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '-0.2px',
      },
      dividerRoot: {
        background: theme.palette.grey.A100,
        margin: theme.spacing(21 / 8, 0, 30 / 8),
        [theme.breakpoints.up('md')]: {
          margin: theme.spacing(15 / 8, 0, 24 / 8),
        },
      },
    }),
);
