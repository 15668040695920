import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

import useStyles from './styles';
import { ISkeletonCheckoutStep3 } from './types';

const SkeletonCheckoutStep3: FC<ISkeletonCheckoutStep3> = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.wrapper}>
        <Skeleton className={classes.skeleton} />
        <Skeleton className={classes.skeleton} />
      </Box>
    </>
  );
};

export default SkeletonCheckoutStep3;
