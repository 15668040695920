import React, { FC, useMemo } from 'react';

import { find, isArray } from 'lodash';

import { Box, Divider } from '@material-ui/core';

import Typography from 'ui/common/Typography';

import DropDown, {
  dropDownStyles,
  TDropDownOption,
  TRenderSelectValue,
} from 'ui/common/inputs/DropDown';
import Icon from 'ui/common/icons/Icon';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

import { ICreditCardsDropDown, TRenderOption } from './types';

// TODO move to common
const CreditCardsDropDown: FC<ICreditCardsDropDown> = ({
  options,
  addCardOptionValue = 'add_card',
  placeholder,
  onDelete,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const classesDropDown = dropDownStyles({
    placeholder,
    dropDownOptions: [], // need for styles ts
    ...rest,
  });

  /* const handleDeleteCard = useCallback<THandleDeleteCard>(
    (params) => (e) => {
      // avoid choosing dropdown item item
      e.stopPropagation();

      if (onDelete) {
        onDelete(params);
      }
    },
    [onDelete],
  ); */

  const renderAddCardOption = useMemo<TDropDownOption>(() => {
    return {
      id: options.length + 1,
      value: addCardOptionValue,
      text: (
        <Box display="flex" alignItems="center">
          <Box mr={0.875}>
            <Icon
              classes={{ root: classes.iconRoot }}
              name="icon-plus-circled"
              color="primary"
              fontSize="small"
            />
          </Box>
          <Typography variant="body1" fontSize={14} color="initial" className={classes.optionText}>
            {'payments.options.addNewCard'}
          </Typography>
        </Box>
      ),
    };
  }, [options, classes, addCardOptionValue]);

  const renderOption = useMemo<TRenderOption>(
    () =>
      ({ id, icon, text = true }) => {
        return (
          <Box className={classes.rootOption} key={id}>
            <Box mr={1.25} width="28px">
              <Icon classes={{ root: classes.iconRoot }} name={icon} fontSize="small" />
            </Box>
            <Typography variant="body1" fontSize={14} color="initial">
              {isArray(text) ? (
                <Box component="span" className={classes.optionText}>
                  {text[1]}{' '}
                  <Typography
                    display="inline"
                    variant="body1"
                    fontWeight="medium"
                    className={classes.optionText}
                    color="initial"
                  >
                    {text[0]}
                  </Typography>
                </Box>
              ) : (
                text
              )}
            </Typography>

            {/* TODO implement delete + refresh containing page
          {isDelete && (
            <IconButton
              classes={{ root: classes.deleteButton }}
              color="primary"
              aria-label="delete"
              component="span"
              onClick={handleDeleteCard({ id, value })}
            >
              <Icon classes={{ root: classes.deleteIcon }} name="icon-trash" />
            </IconButton>
          )} */}
          </Box>
        );
      },
    [classes],
  );

  const renderDropDownOptions = useMemo<TDropDownOption[]>(() => {
    const paymentOptions: TDropDownOption[] = options.map(({ id, value, icon, text }) => {
      return {
        id,
        value,
        text: renderOption({
          id,
          text,
          value,
          icon,
        }),
      };
    });

    const newTest = { ...renderAddCardOption };

    // add custom last item with divider before
    paymentOptions.push(
      { id: options.length + 2, divider: <Divider className={classes.dividerRoot} /> },
      newTest,
    );

    return paymentOptions;
  }, [renderOption, options, renderAddCardOption, classes]);

  // need for render custom placeholder
  const renderSelectValue = useMemo<TRenderSelectValue>(
    () => (selectValue) => {
      const activeValue = find(options, ['value', selectValue]);

      // if we have selected value and it is card
      if (selectValue && activeValue) {
        return renderOption({ ...activeValue, isDelete: false });
      }
      // if selected value is add new payment
      if (selectValue === addCardOptionValue) {
        return renderAddCardOption.text;
      }
      // if we don't have selected value, render placeholder
      return (
        <Typography className={classesDropDown.placeholder} variant="body1" color="mediumGrey">
          {placeholder}
        </Typography>
      );
    },
    [options, placeholder, classesDropDown, renderOption, addCardOptionValue, renderAddCardOption],
  );

  return (
    <DropDown
      required
      isShowLabel
      label={t('checkout.chooseCard')}
      renderValue={renderSelectValue}
      size="large"
      dropDownOptions={renderDropDownOptions}
      menuItemProps={{
        classes: {
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        },
      }}
      {...rest}
    />
  );
};

export default CreditCardsDropDown;
