import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core';
import { TStylesSkeletonCheckoutStep3 } from './types';

export default makeStyles(
  (theme: Theme): StyleRules<TStylesSkeletonCheckoutStep3> =>
    createStyles({
      skeleton: {
        width: '100%',
        height: theme.spacing(40 / 8),
        [theme.breakpoints.up('md')]: {
          width: '45%',
          height: theme.spacing(60 / 8),
        },
      },
      wrapper: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
        },
      },
    }),
);
