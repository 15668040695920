import { TPaymentsMethodsItem } from 'components/common/checkout/CheckoutStep3/components/types';
import { useCart } from 'hooks/useCart';
import { useDialog } from 'hooks/useDialog';
import { useWebsiteDetails } from 'hooks/useWebsiteDetails';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  dialogActions,
  GENERIC_DIALOG,
  orderActions,
  prepareToPlaceOrderSelectors,
  TPlaceOrderPaymentMethodFromUI,
} from 'store';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import { THandleCheckoutSubmit, TPlaceOrder, TPreparePaymentMethods, TUseCheckout } from './types';

const useCheckout: TUseCheckout = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showDialog, hideDialog } = useDialog();

  const prepareToPlaceOrder = useSelector(prepareToPlaceOrderSelectors.getPrepareToPlaceOrder);
  const orderDetails = useSelector(getOrderDetails);

  const { itemsEstimation, cartEstimation } = useCart();
  const websiteDetails = useWebsiteDetails();

  const preparePaymentsMethods = useCallback<TPreparePaymentMethods>(
    (deferredPaymentAllowed, noPaymentMethodAllowed) => {
      const paymentArr: TPaymentsMethodsItem[] = [
        {
          id: 'creditCard',
          text: 'checkout.paymentMethod.creditCard',
          iconName: 'icon-credit-card-2',
        },
      ];
      if (websiteDetails.websiteSettings.bitPaymentAllowed) {
        paymentArr.push({
          id: 'bit',
          text: 'checkout.paymentMethod.bit',
          iconName: 'icon-bit',
        });
      }

      if (websiteDetails.websiteSettings.paymentToCourierAllowed) {
        paymentArr.push({
          id: 'cash',
          text: 'checkout.paymentMethod.cash',
          iconName: 'icon-cash',
        });
      }
      if (deferredPaymentAllowed) {
        paymentArr.push({
          id: 'deferredPayment',
          text: 'checkout.paymentMethod.deferredPayment',
          iconName: 'icon-cycle-2',
        });
      }

      if (noPaymentMethodAllowed) {
        paymentArr.push({
          id: 'absent',
          text: 'checkout.paymentMethod.absent',
          iconName: 'icon-no-payment',
        });
      }

      return paymentArr;
    },
    [
      websiteDetails.websiteSettings.bitPaymentAllowed,
      websiteDetails.websiteSettings.paymentToCourierAllowed,
    ],
  );

  const placeOrder = useCallback<TPlaceOrder>(
    (paymentMethod, numberOfPayments, activePaymentType, uiHelpers, updateSubscription) => {
      dispatch(
        orderActions.placeOrderRequestAction(
          cartEstimation,
          activePaymentType,
          paymentMethod,
          numberOfPayments,
          updateSubscription,
          uiHelpers,
        ),
      );
    },
    [cartEstimation, dispatch],
  );

  const minimumOrderPassed = useMemo<boolean>(() => {
    return itemsEstimation >= prepareToPlaceOrder.orderMinTotalValue;
  }, [itemsEstimation, prepareToPlaceOrder.orderMinTotalValue]);

  const showUpdateSubscriptionDialog = useCallback(
    (placeOrderCallback) => {
      const dialogBodyText =
        websiteDetails.websiteSettings.courierTipEnabled && orderDetails.courierTip
          ? `${t('dialog.updateSubscription.body')}  ${t(
              'dialog.updateSubscription.bodyIfTipExist',
            )}`
          : `dialog.updateSubscription.body`;

      showDialog({
        dialogType: GENERIC_DIALOG,
        contentProps: {
          title: `dialog.updateSubscription.title`,
          body: dialogBodyText,
          buttons: [
            {
              text: `dialog.updateSubscription.okButton`,
              variant: 'contained',
              onClick: () => {
                if (placeOrderCallback) {
                  placeOrderCallback(true);
                }
                hideDialog();
              },
            },
            {
              text: `dialog.updateSubscription.cancelButton`,
              variant: 'outlined',
              onClick: () => {
                if (placeOrderCallback) {
                  placeOrderCallback(false);
                }
                hideDialog();
              },
            },
          ],
        },
      });
    },
    [
      websiteDetails.websiteSettings.courierTipEnabled,
      orderDetails.courierTip,
      t,
      showDialog,
      hideDialog,
    ],
  );

  const handleCheckoutSubmit = useCallback<THandleCheckoutSubmit>(
    (arg, activePaymentType, uiHelpers, validateIfMinimumOrderPassed = true) => {
      if (validateIfMinimumOrderPassed) {
        const body =
          prepareToPlaceOrder.serviceAreaUsageFees === 0
            ? t('dialog.orderMinimum.bodyIfNoDeliveryFee', {
                orderMinimum: prepareToPlaceOrder.orderMinTotalValue,
              })
            : t(`dialog.orderMinimum.body.${orderDetails.orderType}`, {
                orderMinimum: prepareToPlaceOrder.orderMinTotalValue,
              });

        if (!minimumOrderPassed) {
          dispatch(
            dialogActions.showDialog({
              dialogType: GENERIC_DIALOG,
              contentProps: {
                title: 'dialog.orderMinimum.title',
                body,
                buttons: [
                  {
                    text: 'button.ok',
                    variant: 'contained',
                    closeButton: true,
                  },
                ],
              },
            }),
          );
          return;
        }
      }

      const paymentMethod: TPlaceOrderPaymentMethodFromUI = {};

      if (arg.creditCardValue) {
        paymentMethod.id = arg.creditCardValue;
      } else if (arg.values) {
        paymentMethod.formValues = arg.values;
        paymentMethod.formikHelpers = arg.formikHelpers;
      } else if (arg.externalFormValues) {
        paymentMethod.externalFormValues = arg.externalFormValues;
      }

      if (orderDetails.subscription?.id) {
        showUpdateSubscriptionDialog((updateSubscription: boolean) =>
          placeOrder(
            paymentMethod,
            arg.numberOfPayments,
            activePaymentType,
            uiHelpers,
            updateSubscription,
          ),
        );
        return;
      }

      return placeOrder(paymentMethod, arg.numberOfPayments, activePaymentType, uiHelpers);
    },
    [
      dispatch,
      minimumOrderPassed,
      orderDetails.orderType,
      orderDetails.subscription?.id,
      placeOrder,
      prepareToPlaceOrder.orderMinTotalValue,
      prepareToPlaceOrder.serviceAreaUsageFees,
      showUpdateSubscriptionDialog,
      t,
    ],
  );

  return {
    preparePaymentsMethods,
    minimumOrderPassed,
    handleCheckoutSubmit,
  };
};

export default useCheckout;
