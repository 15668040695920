import { TCreditCard } from 'types';

export const getCreditCardBrand = (
  creditCard?: TCreditCard['creditCard'],
  returnAsAnIconName?: boolean,
): string | undefined => {
  if (creditCard) {
    if (
      creditCard.brand.name === 'visa' ||
      creditCard.brand.name === 'mastercard' ||
      creditCard.brand.name === 'isracard' ||
      creditCard.brand.name === 'americanExpress'
    ) {
      return returnAsAnIconName ? `icon-${creditCard.brand.name}` : creditCard.brand.name;
    }

    // TODO add default icon
    if (creditCard.representative.name === 'visaCAL') {
      return returnAsAnIconName ? 'icon-diners' : 'diners';
    }

    return returnAsAnIconName
      ? `icon-${creditCard.representative.name}`
      : creditCard.representative.name;
  }
};
