import { Box, Divider } from '@material-ui/core';
import classNames from 'classnames';

import { useMobile } from 'hooks/useMobile';
import React, { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { orderDetailsActions } from 'store';
import { getOrderDetails } from 'store/modules/orderDetails/selectors';
import CheckBoxWithLabel from 'ui/common/inputs/CheckBoxWithLabel/CheckBoxWithLabel';
import TooltipOnIcon from 'ui/common/TooltipOnIcon';

import Typography from 'ui/common/Typography/Typography';
import useStyles from './styles';
import { IAgeValidationSection } from './types';

const AgeValidationSection: FC<IAgeValidationSection> = ({ tooltipTitle }) => {
  const { isMobile } = useMobile();

  const classes = useStyles();

  const dispatch = useDispatch();
  const orderDetails = useSelector(getOrderDetails);

  return (
    <Box>
      <Typography
        className={classNames(classes.ageValidationTitle, 'font-family-heebo')}
        variant="body2"
      >
        {'ageValidation.ageValidation'}
      </Typography>
      <Box className={classes.limitationItemsExistWrapper}>
        <Typography
          className={classNames(classes.limitationItemsExist, 'font-family-rubik')}
          variant="body1"
        >
          {'ageValidation.orderContainAgeLimitedItems'}
        </Typography>
        <TooltipOnIcon tooltipTitle={tooltipTitle} />
      </Box>
      <Box>
        <CheckBoxWithLabel
          checkBoxSize={isMobile ? 'xsmall' : 'medium'}
          label={'ageValidation.ageConformation'}
          classes={{ label: classes.label }}
          checkBoxProps={{
            checked: orderDetails.limitationByAgeChecked,
            onChange: (event) => {
              dispatch(orderDetailsActions.updateLimitationByAgeChecked(event.target.checked));
            },
          }}
        />
      </Box>
      <Divider className={classes.dividerRoot} />
    </Box>
  );
};

export default AgeValidationSection;
